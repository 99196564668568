<template>
    <common-form
            ref="form"
            :width="850"
            url="web/iot/water/model"
            contentType="json"
            :edit="edit"
            :items="items"
            :beforeRequest="beforeRequest"
            @success="$emit('success')">


        <div class="block" slot="params">
            <div class="item-container" v-for="item in paraArray">
                <div class="item" v-bind:class="{'item-disable':!item.display}">
                    <a-input v-model="item.name" class="input" readonly>
<!--                        <a-icon slot="suffix" type="edit" />-->
                    </a-input>
                    <a-checkbox size="small" v-model="item.display">是否显示</a-checkbox>
                </div>
            </div>
            <div style="clear: both"></div>
        </div>

        <div class="block" slot="switchs">
            <div class="item-container" v-for="item in switchArray">
                <div class="item" v-bind:class="{'item-disable':!item.display}">
                    <a-input v-model="item.name" class="input">
<!--                        <a-icon slot="suffix" type="edit" />-->
                    </a-input>
                    <a-checkbox size="small" v-model="item.display">是否显示</a-checkbox>
                </div>
            </div>
            <div style="clear: both"></div>
        </div>


    </common-form>
</template>

<script>

    import WaterParaUtil from "../WaterParaUtil"
    export default {
        data() {
            return {
                edit: false,
                userName: "",
                paraArray:[],
                switchArray:[]
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "input",
                        name: "型号",
                        key: "model",
                        required:"true",
                        disable:this.edit
                    },
                    {
                        type: "radio-button",
                        name: "设备类型",
                        key: "productKey",
                        required:"true",
                        options: [
                            {name: "水质监控", value: "WATER_MONITOR"},
                            {name: "智能控制系统", value: "WATER_CONTROL"},
                        ]
                    },
                    {
                        type: "slot",
                        name: "设备参数",
                        slot: "params",
                    },
                    {
                        type: "slot",
                        name: "开关控制",
                        slot: "switchs",
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                model = {...model}
                this.paraArray = WaterParaUtil.defaultParaArray()
                this.switchArray = WaterParaUtil.defaultSwitchArray()
                this.$refs.form.show(model)

                if(model.id>0){

                    this.paraArray = JSON.parse(model.parasJson)
                    this.switchArray = JSON.parse(model.switchsJson)

                    // this.$get(`web/iot/water/model/${model.id}`).then(suc=>{
                    //     this.paraArray = suc.data.paraSettings
                    //     this.switchArray = suc.data.switchSettings
                    // })
                }
            },
            beforeRequest(model){
                return {
                    ...model,
                    parasJson: JSON.stringify(this.paraArray),
                    switchsJson: JSON.stringify(this.switchArray)
                    // paraSettings:this.paraArray,
                    // switchSettings:this.switchArray
                }
            },

        }

    }
</script>
<style lang="less" scoped>
    .block {
        padding: 5px;
        background: #FFF;
        border-radius: 5px;
        border: 1px solid #F0F0F0;

        .item-container {
            width: 25%;
            float: left;
            padding: 5px;
        }

        .item {
            border-radius: 5px;
            background: #F8F8F8;
            padding: 5px 12px;
            height: 100px;

            .input{
                margin-top: 12px;
                margin-bottom: -10px;
            }
        }

        .item-disable{
            opacity: 0.5;
        }

    }
</style>
